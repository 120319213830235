<template>
  <el-container class='height-calc-type1'>
    <aside-category
      class='border-radius-6'
      :cateType="1"
      @asideSelect="handleSelect"
      @asideToEdit="toEdit"
    ></aside-category>
    <el-container class="customer-container">
      <el-header class="sub-menu padding-right-left-5 border-radius-6 box-shadow-light-grey">
        <el-menu
          router
          mode="horizontal"
          style="border: 0"
          :default-active="activeSubMenu"
        >
          <el-menu-item
            class="margin-right-23"
            :index="item.path"
            v-for="item in classifyMenu[0].children"
            :key="item.path"
          >
            <i style="font-size: 14px" class="iconfont" :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-header>
      <el-main class="main-cont border-radius-6 padding-20 box-shadow-light-grey">
        <router-view :categoryId="Number(activeIndex)" />
      </el-main>
    </el-container>
    <el-drawer
      :title="`${editForm.id ? '编辑' : '新增'}分类`"
      :visible.sync="isEdit"
      size="450px"
    >
      <!-- <el-dialog
      :title="`${editForm.id ? '编辑' : '新增'}分类`"
      :visible.sync="isEdit"
      width="450px"
      :before-close="() => (this.isEdit = false)"
    > -->
      <el-form label-width="60px">
        <el-form-item label="名称">
          <el-input v-model="editForm.name" placeholder="分类名称" />
        </el-form-item>
        <el-form-item label="类别">
          <el-select
            v-model="editForm.type"
            style="width: 100%"
            :clearable="false"
            disabled
          >
            <el-option label="客服消息" :value="1"></el-option>
            <el-option label="巨量引擎" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="isEdit = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitEdit">确 定</el-button>
      </div>
      <!-- </el-dialog> -->
    </el-drawer>
  </el-container>
</template>

<script>
import { getClassifyList, editClassify, changeClassify } from '@/api/classify'
import { classifyMenu } from '@/assets/js/nav'
import { mapActions } from 'vuex'
import AsideCategory from '../category'

export default {
  name: 'ClassifyCustomer',
  data () {
    return {
      activeIndex: null,
      classifyList: [],
      loading: false,
      search: '',
      activeSubMenu: this.$route.meta.thirdLeveMenu || this.$route.path,
      editForm: {
        type: 1,
        name: ''
      },
      isEdit: false,
      oceanClassify: [],
      page: 1,
      total: 0,
      type: 'service',
      classifyMenu
    }
  },
  watch: {
    $route (newVal) {
      this.activeSubMenu = newVal.meta.thirdLeveMenu || newVal.path
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  methods: {
    handlePageChange (page) {
      this.page = page
      this.getOceanClassify()
    },
    // handleTabChange(tab) {
    //   if (tab.label === '客服消息') {
    //     this.type = 'service';
    //     this.init();
    //   } else {
    //     this.getOceanClassify();
    //     this.type = 'ocean'
    //   }
    // },
    getOceanClassify () {
      this.loading = true
      getClassifyList({
        type: 2,
        isPage: 1,
        page: this.page
      })
        .then(res => {
          this.oceanClassify = res.list
          this.total = res.total
        })
        .finally(() => (this.loading = false))
    },

    handleSubmitEdit () {
      if (this.editForm.name) {
        editClassify(this.editForm).then(() => {
          if (this.type === 'service') {
            this.init()
          } else {
            this.getOceanClassify()
          }
          this.$message.success('保存成功')
          this.isEdit = false
        })
      } else {
        this.$message.error('请输入分类名称')
      }
    },
    handleCommand (command, item) {
      switch (command) {
        case 'edit':
          this.toEdit(item)
          break
        case 'enable':
          this.handleChangeStatus(item, 1)
          break
        case 'disable':
          this.handleChangeStatus(item, 0)
          break
      }
    },
    handleChangeStatus (item, status) {
      this.$confirm(`是否${status === 1 ? '启用' : '禁用'}该分类`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeClassify(item.id, status).then(() => {
            this.init()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    toEdit (data, type = 1) {
      if (data) {
        this.editForm = {
          type: data.type,
          name: data.name,
          id: data.id
        }
      } else {
        this.editForm = {
          type,
          name: ''
        }
      }
      this.isEdit = true
    },
    init () {
      this.loading = true
      getClassifyList({
        type: 1,
        name: this.search
      })
        .then(res => {
          this.classifyList = res
          const classify = sessionStorage.getItem('classify')
          this.activeIndex = classify || `${res[0].id}`
        })
        .finally(() => (this.loading = false))
    },
    handleSelect (item) {
      sessionStorage.setItem('classify', item)
      this.activeIndex = item
    },
    // handleMenuClick(path) {
    //   const menuData = this.classifyMenu.find((item) => item.path === path);
    //   console.log(menuData);
    //   this.addRouterHistory({
    //     params: {},
    //     title: menuData.title,
    //     path: menuData.path,
    //   });
    // },
    ...mapActions({
      addRouterHistory: 'addRouterHistory'
    })
  },
  components: {
    AsideCategory
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  box-sizing: border-box;
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
/deep/ .el-menu-item{
  font-size:16px !important;
}
.search-cont {
  padding: 15px;
  border-bottom: 1px solid #ececec;
}
.customer-container {
  flex-direction: column;
  box-sizing: border-box;
  height:calc(100vh - 152px);
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
  /deep/ .el-menu {
    height: 52px !important;
    display: flex;
    align-items: center;
    .el-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.el-menu-demo {
  border: 0;
  overflow: auto;
  flex: 1;
}

.side-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.input-with-select {
  width: 100%;
}

.class-name {
  flex: 1;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// .sub-menu .el-menu-item,
// .el-submenu__title {
//   height: 52px;
//   line-height: 52px;
// }

.sub-menu {
  margin-bottom:16px;
  margin-left:16px;
  height:auto !important;
  box-sizing: border-box;
  background:#fff;

}

.el-dropdown-link {
  font-size: 12px;
}

.main-cont {
  margin-left: 16px;
  background-color: #fff;
  overflow: auto;
}

.tab-cont {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.el-tab-pane {
  height: 100%;
  background: #f2f5f3;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
